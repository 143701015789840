// module.exports = {
//     graphQlUrl: `https://admin.server.getagil.com/comercio-admin-backend/admin/graphql`,
//     backendUrl: `https://admin.server.getagil.com/comercio-admin-backend/`,
//     LAMBDA_AUTH: "https://lambda.getagil.com/prod-auth",
//     LAMBDA_ADMIN: "https://lambda.getagil.com/prod-admin",
//     LAMBDA_COMMON: "https://lambda.getagil.com/prod-common",
//     AppKey: 'aece4bc5db1d757be2cc2b2416f08299',
//     posFrontUrl: `https://pos.getagil.com/`,
//     API_KEY_MAPS: 'AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM',
//     AUTH_URL: "https://account.getagil.com",
// }

module.exports = {
    graphQlUrl: `https://admin.server.getagil.com/comercio-admin-backend/admin/graphql`,
    backendUrl: `https://admin.server.getagil.com/comercio-admin-backend/`,
    LAMBDA_AUTH: "https://lambda.getagil.com/prod-auth",
    LAMBDA_ADMIN: "https://lambda.getagil.com/prod-admin", // deprecando...
    LAMBDA_ALIADOS_13489AA: "https://lambda.getagil.com/aliados-13489AA",
    LAMBDA_COMMON: "https://lambda.getagil.com/prod-common",
    LAMBDA_POS: "https://lambdsa.getagil.com/prod-pos ",
    LAMBDA_SHOP: "https://lambda.getagil.com/prod-shop",
    LAMBDA_LINCE: 'https://lambda.getagil.com/lince',
    AppKey: 'aece4bc5db1d757be2cc2b2416f08299',
    posFrontUrl: `https://partner.getagil.com`,
    API_KEY_MAPS: 'AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM',
    AUTH_URL: "https://account.getagil.com",
    TINTY_MCE: "owiocz2hm60g3svswoaqir51ifhobqcd41iycbl1h4osao2a",
    SOCKET_URL: "https://socket.server.getagil.com/",
    LAMBDA_MARKET: "https://lambda.getagil.com/market",
    LAMBDA_SUPER: "https://lambda.getagil.com/prod-super",
    LAMBDA_API: "https://lambda.getagil.com/prod-agil",
}
